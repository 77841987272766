import React, { Component } from 'react';
import Notice from '../components/notice';
import { Helmet } from "react-helmet";
import ErrorList from '../components/errors';
import Footer from '../components/footer';

class Contact extends Component {
    constructor(props) {
        super(props);
        // set menu to black
        this.props.toggleMenu(true);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.form = {
        };
        this.state = {
            errors: '',
            errorMessage: '',
            success: '',
        }
    }
    validateEmail(email) {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(String(email).toLowerCase());
    }
    valid(){
        const errors = [];
        if(this.form.name.value.length < 2){
            errors.push('Enter name');
        }
        if(!this.validateEmail(this.form.email.value)){
            errors.push('Enter valid email address');
        }
        if(this.form.message.value.length < 6){
            errors.push('Enter message');
        }
        this.setState({errors});
        if(errors.length === 0){
            return true;
        }
        return false;
    }
    handleSubmit(event) {
        event.preventDefault();
        if(this.valid()){
            fetch(`${process.env.REACT_APP_API}/api/contact`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    from: this.form.email.value,
                    name: this.form.name.value,
                    message: this.form.message.value,
                })
            }).then(()=>{
                this.setState({success: true});
                this.form.name.value = "";
                this.form.email.value = "";
                this.form.message.value = "";
                setTimeout(() =>  {
                    this.setState({success: false});
                    this.setState({form: {}});
                }, 1000);
            }).catch((e) => {
                this.setState({invalidIcon: 'show', errorMessage: "Please try again later"});
            });
        } else {
            this.setState({invalidIcon: 'show'});
            return;
        }
    }
    render() {
        return (
        <div>
          <section className="firstSection">
            <Helmet>
              <title>Contact</title>
              <meta name="description" content='Contact' />
              <meta property="og:title" content='Contact' />
            </Helmet>
            <div className="content">
              <h1 className="App-title">Contact</h1>
            </div>
            <div className="content">
              <div className="half">
                <h3 className="App-title">Billing:</h3>
                <p>
                  <b>Name:</b> Vademo BVBA<br/>
                  <b>BTW:</b> BE 0658 876 062
                </p>
                <div className="socialLinks">
                  <a href="https://twitter.com/Oliviervademo" target="_black">
                    <img src="twitter.svg" alt="twitter link"/>
                  </a>
                  <a href="https://github.com/vademo" target="_black">
                    <img src="github.svg" alt="github link"/>
                  </a>
                  <a href="https://www.behance.net/vademo" target="_black">
                    <img src="behance.svg" alt="behance link"/>
                  </a>
                </div>
              </div>
              <div className="half">
                  <h3 className="App-title">Send me a message:</h3>
		  <p><strong>Email:</strong> <a href="mailto:info@vademo.be" className="email">info@vademo.be</a></p>
              </div>
            </div>
          </section>
          <Footer />
        </div>
        );
    }
}

export default Contact;
